import { CDPDeviceType } from './cdp-types'
import platformJs from 'platform'

export class UAParser {
  private readonly platform: typeof platformJs | null
  private readonly userAgent: string | null

  constructor(userAgent: string | null) {
    this.userAgent = userAgent
    this.platform = userAgent ? platformJs.parse(userAgent) : null
  }

  public getOs(): string | null {
    if (this.platform?.os?.family) {
      return this.platform.os.family + ' ' + this.platform.os.version
    }
    return null
  }

  public getBrowser(): string | null {
    if (this.platform?.name) {
      return this.platform.name + ' ' + this.platform.version
    }

    return null
  }

  public getDevice(): CDPDeviceType | null {
    if (!this.userAgent) {
      return null
    }

    const ua = this.userAgent.toLowerCase()
    const mobileIndicators = [
      'iphone',
      'ipod',
      'ipad',
      'android',
      'blackberry',
      'opera mini',
      'windows phone',
      'mobile',
    ]

    for (const indicator of mobileIndicators) {
      if (ua.includes(indicator)) {
        return 'mobile'
      }
    }

    return 'desktop'
  }
}
