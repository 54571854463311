import { RefObject, useEffect, useRef } from 'react'
import { PostContext } from 'shared-components/contexts/PostContext'
import { AppEventsContext } from '../contexts/AppEventsContext'

export function useOutboundLinkListener<T extends HTMLElement>(): RefObject<T> {
  const { appEvent } = AppEventsContext.useContainer()
  const postMeta = PostContext.useContainer()
  const ref = useRef<T>(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const el = ref.current

    const handler = (e: DocumentEventMap['click']): void => {
      if (!(e.target instanceof HTMLAnchorElement)) {
        return
      }

      if (e.target.getAttribute('target') === '_blank') {
        appEvent({
          linkUrl: e.target.href,
          linkText: e.target.innerText,
          event: 'outbound-link',
          desc: 'Outbound link click',
          postMeta,
        })
      }
    }

    el.addEventListener('click', handler, { passive: true })
    return () => {
      el.removeEventListener('click', handler)
    }
  }, [postMeta])

  return ref
}
