/* eslint-disable @typescript-eslint/require-await */
import { logError, logNotice } from '../log'
import type { CDPEvent, CDPRuntimeEvent, CDPContext, CDPEventPostProcessor } from './cdp-types'
import { UAParser } from './user-agent-parser'

interface CDPClientOptions {
  userId: string | null
  eventsCollectorUrl: string
}

export class CDPClient<T extends CDPRuntimeEvent> {
  private readonly options: CDPClientOptions

  public constructor(opts: CDPClientOptions) {
    this.options = opts
  }

  public async track(event: T, processor?: CDPEventPostProcessor): Promise<void> {
    const timestamp = new Date().toISOString()

    const eventPayload: CDPEvent = {
      sessionId: this.getSessionId(),
      userId: this.options.userId,
      type: 'track',
      eventName: event.name,
      originalTimestamp: timestamp,
      timestamp,
      sentAt: timestamp,
      properties: event.properties,
      context: this.getContext(),
      integrations: {},
    }

    const eventToSend = processor ? processor(eventPayload) : eventPayload

    logNotice('CDP Event:', eventToSend)
    await fetch(this.options.eventsCollectorUrl, {
      method: 'POST',
      body: JSON.stringify(eventToSend),
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch(error => {
      logError('CDP Event error:', error)
    })
  }

  private getSessionId(): string | null {
    const sessionId = localStorage.getItem('cdpSessionId')
    if (sessionId) {
      return sessionId
    }

    const newSessionId = crypto.randomUUID()
    localStorage.setItem('cdpSessionId', newSessionId)
    return newSessionId
  }

  private getContext(): CDPContext {
    const userAgent = navigator.userAgent ?? null
    const parser = new UAParser(userAgent)

    return {
      userAgent,
      deviceType: parser.getDevice(),
      os: parser.getOs(),
      browser: parser.getBrowser(),
      page: {
        url: window.location.href,
      },
    }
  }
}
