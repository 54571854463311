import { useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { CDPClient } from 'shared-code/cdp/library'
import { logWarning } from 'shared-code/log'

interface UseGlobalCdpClientOptions {
  eventsCollectorUrl?: string
}

export function useGlobalCdpClient(opts: UseGlobalCdpClientOptions): void {
  const { authState } = AuthContext.useContainer()
  useEffect(() => {
    if (opts.eventsCollectorUrl === undefined) {
      logWarning('CDP Event Client: eventsCollectorUrl is undefined')
      return
    }

    if (authState?.profile) {
      window.cdpClient = new CDPClient({
        userId: authState.profile.sub,
        eventsCollectorUrl: opts.eventsCollectorUrl,
      })
    } else {
      window.cdpClient = new CDPClient({
        userId: null,
        eventsCollectorUrl: opts.eventsCollectorUrl,
      })
    }
  }, [authState])
}
